/** @format */

import React from "react";

import { Link } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

function Roadmap() {
  return (
    <>
      <div
        className='container-fluid showroom-bg roadmap pt-2'
        style={{ marginTop: "-70px" }}>
        <div className='container pt-5'>
          <div className='row'>
            <div className='col-md-4 list-items m-auto'>
              <ul className='list-unstyled '>
                <li>
                  <Link to='/why'>Why $0xFutures</Link>
                </li>
                <li>
                  <Link to='/roadmap' className='active mx-3'>
                    Roadmap
                  </Link>
                </li>
                <li>
                  <Link to='/tokenomics'>Tokenomics</Link>
                </li>
                <li>
                  <Link to='/mission-vision'>Mission & Vision</Link>
                </li>

                <li>
                  <Link to='/news'>0xFutures news</Link>
                </li>
              </ul>
            </div>
            <div className='col-md-8'>
              <div className='detail-row' style={{ height: "70vh" }}>
                <div className='row'>
                  <div className='col-md-12 m-auto pt-5'>
                    <img
                      src='assets/roadmap.png'
                      alt=''
                      className=' d-block  m-auto '
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row position-fixed icnons end-0 mx-5 bottom-0 my-2'>
            <div className='col-md-12 text-end'>
              <p className='next-para'>Roadmap</p>
              <div className='next-btn text-end'>
                <Link to='/why' className='prev-arrow'>
                  <BsArrowRight size={30} />
                </Link>
                <Link to='/tokenomics' className='next-arrow'>
                  <BsArrowLeft size={30} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Roadmap;
