/** @format */

import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";
import { Link as Links } from "react-scroll";

function Base(props) {
  return (
    <>
      <div className='slide-web'>
        <ul className='list-unstyled'>
          <li>
            <Links
              delay={150}
              offset={0}
              duration={500}
              containerId='.hero'
              controlfor='.hero'>
              <Link to='/' className='item'>
                <span className='number'>1</span>
              </Link>
            </Links>
          </li>
          <li>
            <Links delay={150} offset={0} duration={500} controlfor='.features'>
              <Link to='/features' className='item'>
                <span className='number'>2</span>
              </Link>
            </Links>
          </li>
          <li>
            <Links delay={150} offset={0} duration={500} controlfor='.why'>
              <Link to='/why' className='item'>
                <span className='number'>3</span>
              </Link>
            </Links>
          </li>
          <li>
            <Links delay={150} offset={0} duration={500} controlfor='.roadmap'>
              <Link to='/roadmap' className='item'>
                <span className='number'>4</span>
              </Link>
            </Links>
          </li>
          <li>
            <Links
              delay={150}
              offset={0}
              duration={500}
              controlfor='.tokenomics'>
              <Link to='/tokenomics' className='item'>
                <span className='number'>5</span>
              </Link>
            </Links>
          </li>
          <li>
            <Links
              delay={150}
              offset={0}
              duration={500}
              controlfor='.our-history'>
              <Link to='/faqs' className='item'>
                <span className='number'>6</span>
              </Link>
            </Links>
          </li>
        </ul>
      </div>
      <Header />
      {props.children}
      <Footer />
    </>
  );
}

export default Base;
