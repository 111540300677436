/** @format */

import { useEffect, useState } from "react";

const Timer = () => {
  const [days, setdays] = useState(null);
  const [hours, sethours] = useState(null);
  const [minutes, setminutes] = useState(null);
  const [seconds, setseconds] = useState(null);

  var countDownDate = new Date("Oct 13, 2023 13:00:00 UTC");
  var GetIme = new Date(countDownDate).getTime();

  setInterval(() => {
    var now = new Date().getTime();
    var distance = GetIme - now;
    var day = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hour = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    var minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var second = Math.floor((distance % (1000 * 60)) / 1000);
    setdays(day);
    sethours(hour);
    setminutes(minute);
    setseconds(second);
  }, 1000);
  return (
    <>
      {seconds > -1 && (
        <>
          <div className='d-flex flex-row justify-content-between align-content-center '>
            <p className='timer-p'>Launch in</p>
            <a
              href='https://app.uniswap.org/swap?outputCurrency=0xB58d239F50a285DDeD64C3d91BeFfdB829364138'
              target='_blank'
              className='text-warning fw-bold fs-6'
              rel='noopener noreferrer'>
              <img
                src='assets/uniswap-wallet-icon.12b3568891522db07d59.png'
                style={{ width: 20, display: "inline-block" }} className=" rounded-circle "
                alt=''
              />{" "}
              &nbsp; Trade on Uniswap
            </a>
          </div>
          <div
            style={{ display: "flex" }}
            className='timer-section w-100 text-center mt-2'>
            <h1 className='' id='hours'>
              <strong>{days} </strong>
              <small>DAYS</small>
            </h1>
            <h1 className='' id='hours'>
              <strong>{hours} </strong>
              <small>HOURS</small>
            </h1>
            <h1 className='' id='minutes'>
              <strong>{minutes} </strong>
              <small>MINUTES</small>
            </h1>
            <h1 className='' id='seconds'>
              <strong>{seconds} </strong>
              <small>SECONDS</small>
            </h1>
          </div>
        </>
      )}
    </>
  );
};

export default Timer;
