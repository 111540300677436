import React from "react";
import { Link, useLocation } from "react-router-dom";
import {BsArrowRight} from 'react-icons/bs'


function GameInfo() {
  const location = useLocation();
  console.log(location.state);
  const v = location.state;

  return (
    <div
      className="container-fluid news-bg pt-4 pb-5 showroom-bg"
      style={{ marginTop: "-70px" }}
    >
      <div className="container mt-5">
        {/* <Link to="/game-info" >See more</Link> */}
     
          <div className="detail-row">
            <div className="row">
              <div className="col-md-2">
                <div className="next-btn">
                <Link to="/news" className="prev-arrow">
                  <BsArrowRight size={30} />
                </Link>
                </div>
              </div>

              <div className="col-md-8  gameinfo">
                <p>0xFutures /latest news</p>
                {/* <p>{v.date}</p> */}
                <h1>{v.heading1}</h1>
                <br />
                <figure className="figure">
                  <img src={v.img} alt="" className="w-75 d-block m-auto" />
                  <figcaption className="figure-caption">{v.imgCap}</figcaption>
                </figure>
                <h4>{v.des1}</h4>
                <br />
                <h4>{v.des2}</h4>
               
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default GameInfo;
