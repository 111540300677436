/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
function Partner() {
  const logos = [
    {
      img: "/assets/trust-7.png",
      link: "https://www.pinksale.finance/launchpad/0xf1fF76d5eA22629DF133458e8d8dD2E4c0ec0153?chain=ETH",
    },
    {
      img: "/assets/dexview.svg",
      link: "https://www.dexview.com/eth/0xB58d239F50a285DDeD64C3d91BeFfdB829364138",
    },
    {
      img: "/assets/2560px-Uniswap_Logo_and_Wordmark.png",
      link: "https://app.uniswap.org/swap?outputCurrency=0xB58d239F50a285DDeD64C3d91BeFfdB829364138",
    },

    {
      img: "/assets/dextool.png",
      link: "https://www.dextools.io/app/en/ether/pair-explorer/0x126144e59afa9f39f9bedbee765e6f5d42ff391a",
    },
    {
      img: "/assets/logo-white.svg",
      link: "https://blocksafu.com/audit/0xB58d239F50a285DDeD64C3d91BeFfdB829364138",
    },
    {
      img: "/assets/etherscan-logo.svg",
      link: "https://etherscan.io/address/0xB58d239F50a285DDeD64C3d91BeFfdB829364138",
    },
    {
      img: "/assets/lbank.svg",
    },
    {
      img: "/assets/unicript.png",
    },

    {
      img: "/assets/tg.png",
    },
  ];
  return (
    <div
      className='container-fluid news-bg pt-4 pb-3 showroom-bg'
      style={{ marginTop: "-70px" }}>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-2 list-items m-auto'></div>
          <div className='col-md-10 partner'>
            <p>leading partnars</p>
            <h3>
              0xFutures is working with best-in-class worldwide partners to
              revolutionize the meaning of immersive experiences.
            </h3>
            <div className='row mt-5'>
              {logos.map((v, i) => {
                return (
                  <div className='col-md-4 col-6 mt-5' key={i}>
                    <a href={v?.link} target='_blank' rel='noopener noreferrer'>
                      {" "}
                      <img src={v?.img} className='w-50' alt='' />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className='row position-fixed icnons end-0 mx-5 bottom-0 my-2'>
          <div className='col-md-12 text-end'>
            <p className='next-para'>partners</p>
            <div className='next-btn text-end'>
              <Link to='/news' className='prev-arrow'>
                <BsArrowRight size={30} />
              </Link>
              <Link to='/faqs' className='next-arrow'>
                <BsArrowLeft size={30} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partner;
