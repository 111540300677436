/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

function Showroom() {
  return (
    <>
      <div
        className='container-fluid showroom-bg features pt-5 pb-3'
        style={{ marginTop: "-70px" }}>
        <div className='container pt-4 '>
          <div className='row'>
            <div className='col-md-4 list-items m-auto'>
              <ul className='list-unstyled '>
                <li>
                  <a href='#!'>Zero-day futures trade</a>
                </li>
                <li>
                  <a href='#!'>Short-Term, Margin-Based Speculation</a>
                </li>
                <li>
                  <a href='#!'>Daily Expiration 25 hours cycle</a>
                </li>
                <li>
                  <a href='#!'>Intraday Funding Payments</a>
                </li>
                <li>
                  <a href='#!'>No Requirement to Borrow</a>
                </li>
              </ul>
            </div>
            <div className='col-md-8'>
              <div className='detail-row' style={{ height: "75vh" }}>
                <div className='row'>
                  <div className='col-md-6 m-auto'>
                    <div className=' portfolio-card text-center m-auto'>
                      <img
                        src={"/assets/bot1.png"}
                        className='w-100 '
                        alt='...'
                      />
                      <div className='detail'>
                        <div className='content'></div>
                      </div>
                    </div>
                    <div className=' portfolio-card text-center m-auto'>
                      <img src={"/assets/bot3.png"} alt='' className='w-100 ' />
                      <div className='detail'>
                        <div className='content'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className=' portfolio-card text-center'>
                      <img
                        src={"/assets/bot2.png"}
                        className='w-100 '
                        alt='...'
                      />
                      <div className='detail'>
                        <div className='content'></div>
                      </div>
                    </div>
                    <div className=' portfolio-card text-center'>
                      <img
                        src={"/assets/bot4.png"}
                        className='w-100 '
                        alt='...'
                      />
                      <div className='detail'>
                        <div className='content'>
                          {/* <a
                            href='#!'
                            // target='_blank'
                            // onClick={handleShow}
                            style={{ cursor: "pointer" }}
                            rel='noopener noreferrer'>
                            <FaEye
                              style={{
                                color: "#BFFF47",
                                fontSize: 35,
                              }}
                            />
                          </a>

                          <h2>No Requirement to Borrow</h2> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row position-fixed icnons end-0 mx-5 bottom-0 my-2'>
            <div className='col-md-12 text-end'>
              <p className='next-para'>Features</p>
              <div className='next-btn text-end'>
                <Link to='/' className='prev-arrow'>
                  <BsArrowRight size={30} />
                </Link>
                <Link to='/why' className='next-arrow'>
                  <BsArrowLeft size={30} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Showroom;
