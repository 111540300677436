/** @format */

import React from "react";
import Faq from "react-faq-component";
import { Link } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
const data = {
  //   title: "FAQ (How it works)",
  rows: [
    {
      title: "What is 0xFutures?",
      content: `0xFutures is an innovative cryptocurrency trading platform that offers unique zero-day futures contracts. These contracts have a 25-hour expiration cycle and can be traded in both long and short positions, providing traders with opportunities to profit from short-term price movements.`,
    },
    {
      title: "What are the advantages of trading zero-day futures?",
      content: `Trading zero-day futures on 0xFutures comes with several advantages. These contracts offer daily USDC settlement, eliminating concerns about extended positions. This feature makes them appealing to market makers and liquidity providers who may have been hesitant due to liquidity concerns.`,
    },
    {
      title: "What are Limit Orders on 0xFutures?",
      content: `Limit Orders on 0xFutures allow you to set specific price levels at which your positions will automatically close for profit (Take Profit) or to limit losses (Stop Loss). These orders help you trade strategically and reduce impulsive decision-making.`,
    },
    {
      title: "Are there any risks associated with futures trading?",
      content: `Yes, futures trading carries inherent risks, including the potential for losses that exceed the initial investment. It's essential for traders to exercise caution, trade responsibly, and only invest funds they can afford to lose.
      `,
    },
    {
      title: "How can I get involved in the 0xFutures community?",
      content: `Engaging with the 0xFutures community is easy. You can participate in their daily competitions, connect with fellow traders, and contribute insights and feedback to be a part of their dynamic community.`,
    },
    {
      title: "Does 0xFutures comply with regulatory standards?",
      content: `Yes, 0xFutures is committed to regulatory compliance and ethical guidelines. They adhere to industry standards like CFTC and prioritize anti-money laundering transactions to align with global regulations.`,
    },
    {
      title: "How does 0xFutures ensure security?",
      content: `0xFutures prioritizes the security of users' assets and data by employing advanced security measures. These include encryption, two-factor authentication, hardware wallets, and thorough audits to maintain the integrity of the platform.`,
    },
  ],
};

const styles = {
  // bgColor: "#0E0E1DBF",
  titleTextColor: "#EBEEF2",
  rowTitleColor: "#bfff47",
  rowContentColor: "#fff",
  arrowColor: "#bfff47",
  rowContentPaddingTop: "10px",
  rowContentPaddingBottom: "10px",
  rowContentPaddingLeft: "30px",
  rowContentPaddingRight: "30px",
};

const config = {
  animate: true,
  // arrowIcon: "V",
  // tabFocus: true
  expandIcon: "+",
  collapseIcon: "-",
  margin: "2px",
};

export default function Faqs() {
  return (
    <>
      <div className='container showroom-bg pt-2  mb-5'>
        <div className='row res-footer'>
        <div className='col-md-2 list-items m-auto'>
          </div>
          <div className='col-md-10 faq-heading partner'>
            <p className='mb-3 '>Faqs</p>
            <div>
              <Faq data={data} styles={styles} config={config} />
            </div>
          </div>
        </div>
        <div className='row position-fixed icnons end-0 mx-5 bottom-0 my-2'>
          <div className='col-md-12 text-end'>
            <p className='next-para'>Faqs</p>
            <div className='next-btn text-end'>
              <Link to='/partner' className='prev-arrow'>
                <BsArrowRight size={30} />
              </Link>
              <Link to='/team' className='next-arrow'>
                <BsArrowLeft size={30} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
