/** @format */

import React from "react";

import { Link } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

function Mission() {
  return (
    <>
      <div
        className='container-fluid showroom-bg pt-2'
        style={{ marginTop: "-70px" }}>
        <div className='container pt-5'>
          <div className='row'>
            <div className='col-md-4 list-items m-auto'>
              <ul className='list-unstyled '>
                <li>
                  <Link to='/why'>Why $0xFutures</Link>
                </li>
                <li>
                  <Link to='/roadmap' >
                    Roadmap
                  </Link>
                </li>
                <li>
                  <Link to='/tokenomics'>Tokenomics</Link>
                </li>
                <li>
                  <Link to='/mission-vision' className='active mx-3'>Mission & Vision</Link>
                </li>
                <li>
                  <Link to='/news'>0xFutures news</Link>
                </li>
              </ul>
            </div>
            <div className='col-md-8'>
              <div className='detail-row pt-5' style={{ height: "80vh" }}>
                <div className='row'>
                  <div className='col-md-6 m-auto modal-contents'>
                    <h1>Mission & Vision</h1>
                    <p>
                      Our mission centers on delivering a high-performance
                      trading bot integrated into the Ethereum blockchain. Our
                      partners and teams share our dedication to providing
                      top-tier services to community members who hold a genuine
                      belief in meme tokens. The Zero Day Futures team is
                      resolute in its commitment to compliance with relevant
                      regulations in China and Dubai, while upholding elevated
                      ethical standards.
                    </p>
                    <p>
                      We recognize the paramount importance of transparency and
                      legal adherence in the cryptocurrency industry. Our
                      approach involves proactive measures to ensure that Zero
                      Day Futures operations consistently align with regulatory
                      frameworks. This, in turn, fosters trust among our
                      community and partners.
                    </p>
                  </div>
                  <div className='col-md-6 modal-img'>
                    <img src='assets/logo2.png' alt='' className='w-100' />
                  </div>
                  <div className='col-md-12 modal-contents'>
                    <p>
                      The 0xFutures community operates as a cohesive and
                      ever-evolving family, with a continuous stream of
                      activities. Our network places a strong emphasis on the
                      security of our community members, bolstered by
                      third-party audits
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row position-fixed icnons end-0 mx-5 bottom-0 my-2'>
            <div className='col-md-12 text-end'>
              <p className='next-para'>Mission & Vision</p>
              <div className='next-btn text-end'>
                <Link to='/tokenomics' className='prev-arrow'>
                  <BsArrowRight size={30} />
                </Link>
                <Link to='/news' className='next-arrow'>
                  <BsArrowLeft size={30} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mission;
