/** @format */

import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

function News(props) {
  const navigate = useNavigate();
  const toComponentB = v => {
    navigate("/news-details", { state: v });
  };
  const newsData = [
    {
      img: "/assets/vid.png",
      text: "0xFutures / latest news",
      heading:
        "First-ever zero-day futures trade with a 25-hour time constraint on a Telegram bot. $0xFutures",
      date: "18.08.2022",
      heading1:
        "First-ever zero-day futures trade with a 25-hour time constraint on a Telegram bot. $0xFutures",
      des1: "ZeroFutures excited to introduce Zero-Day Futures telegram bot, an innovative derivatives contract designed to streamline short-term speculation on cryptocurrency assets.",
      des2: "Zero-day futures (0xFutures) are futures contracts featuring a 25-hour expiration cycle. Similar to traditional expiring or perpetual futures, 0xFutures can be traded in both long and short positions throughout the contract's duration. Any remaining open positions at expiration that are not closed or rolled over will automatically settle in USDC, returning funds to the traders' accounts.",
      imgCap: "",
    },
    // {
    //   img: "",
    //   text: "0xFutures / latest news",
    //   heading:
    //     "",
    //   date: "18.08.2022",
    //   heading1:
    //     "",
    //   des1: "",
    //   des2: "",
    //   imgCap:
    //     "",
    // },
  ];

  return (
    <>
      <div
        className='container-fluid showroom-bg pt-4'
        style={{ marginTop: "-70px" }}>
        <div className='container pt-5'>
          <div className='row'>
            <div className='col-md-4 list-items m-auto'>
              <ul className='list-unstyled '>
                <li>
                  <Link to='/why'>Why $0xFutures</Link>
                </li>
                <li>
                  <Link to='/roadmap'>Roadmap</Link>
                </li>
                <li>
                  <Link to='/tokenomics'>Tokenomics</Link>
                </li>
                <li>
                  <Link to='/mission-vision' >
                    Mission & Vision
                  </Link>
                </li>

                <li>
                  <Link to='/news' className='active mx-3'>0xFutures news</Link>
                </li>
              </ul>
            </div>
            <div className='col-md-8'>
              <h1 className='news-heading mt-4'>
                Latest news from the 0xFutures and Zero Futures Bot
              </h1>
              <div className='detail-row' style={{ height: "60vh" }}>
                <div className='row'>
                  {newsData.map((v, i) => {
                    return (
                      <div
                        className='row'
                        onClick={() => {
                          toComponentB(v);
                        }}
                        key={i}
                        style={{ cursor: "pointer" }}>
                        <div className='col-md-3 mt-4'>
                          <img src={v.img} alt='' className='w-100' />
                        </div>
                        <div className='col-md-9 news-content mt-4'>
                          {/* <p style={{ float: "right" }}>{v.date}</p> */}
                          <p>{v.text}</p>
                          <h1>{v.heading}</h1>
                        </div>
                      </div>
                    );
                  })}
                  <div className='col-md-3'></div>
                </div>
              </div>
            </div>
          </div>

          <div className='row position-fixed icnons end-0 mx-5 bottom-0 my-2'>
            <div className='col-md-12 text-end'>
              <p className='next-para'>News & Info</p>
              <div className='next-btn text-end'>
                <Link to='/mission-vision' className='prev-arrow'>
                  <BsArrowRight size={30} />
                </Link>
                <Link to='/partner' className='next-arrow'>
                  <BsArrowLeft size={30} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
