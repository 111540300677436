/** @format */

import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { SiGitbook, SiProbot } from "react-icons/si";
import { MdDashboard } from "react-icons/md";
function Header() {
  return (
    <header>
      <coingecko-coin-price-marquee-widget
        coin-ids='ethereum,unibot,pancakeswap-token,aimbot,banana-gun'
        currency='usd'
        font-color='#ffee'
        background-color='transparent'
        locale='en'></coingecko-coin-price-marquee-widget>
      <nav className='navbar navbar-expand-lg'>
        <div className='container-fluid'>
          <a className='navbar-brand' href='/'>
            <img src={"assets/logo.png"} width={200} alt='' />
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                <a
                  className='nav-link active'
                  href='https://app.uniswap.org/swap?outputCurrency=0xB58d239F50a285DDeD64C3d91BeFfdB829364138'
                  target='_blank'
                  rel='noreferrer'>
                  <img src='assets/2560px-Uniswap_Logo_and_Wordmark.png' style={{ width: 130 }} alt='' />{" "}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link active'
                  href='https://www.dexview.com/eth/0xB58d239F50a285DDeD64C3d91BeFfdB829364138'
                  target='_blank'
                  rel='noreferrer'>
                  <img src='assets/dexview.svg' style={{ width: 130 }} alt='' />{" "}
                </a>
              </li>
              <li className='nav-item mx-3'>
                <a
                  className='nav-link '
                  href='https://0xfutures.gitbook.io/0xfutures.trade'
                  target='_blank'
                  rel='noreferrer'>
                  Gitbook <SiGitbook color='#BFFF47' size={20} />
                </a>
              </li>
              <li className='nav-item '>
                <a
                  className='nav-link '
                  href='https://dashboard.0xfutures.trade/'
                  target='_blank'
                  rel='noreferrer'>
                  Dashboard <MdDashboard color='#BFFF47' size={20} />
                </a>
              </li>

              {/* <li className='nav-item'>
                <a className='nav-link active' aria-current='page' href='/'>
                  Zero Futures Bot <SiProbot color='#BFFF47' size={20} />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
