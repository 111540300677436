/** @format */

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Base from "./components/layouts/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import Home from "./Pages/Home";
import Showroom from "./components/elements/Showroom";
import News from "./components/elements/News";
import GameInfo from "./components/elements/GameInfo";
import Partner from "./components/elements/Partner";
import Team from "./components/elements/Team";
import Contact from "./components/elements/Contact";
import ReactGA from "react-ga";
import Tokenomics from "./components/elements/Tokenomics";
import Roadmap from "./components/elements/Roadmap";
import Why from "./components/elements/Why";
import Mission from "./components/elements/Mission";
import Faqs from "./components/elements/Faqs";
ReactGA.initialize("G-6QHK9Y6R65");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <BrowserRouter>
      <Base>
        <Routes>
          <Route index path='/' element={<Home />} />
          <Route index path='/features' element={<Showroom />} />
          <Route index path='/news' element={<News />} />
          <Route index path='/news-details' element={<GameInfo />} />
          <Route index path='/partner' element={<Partner />} />
          <Route index path='/team' element={<Team />} />
          <Route index path='/contact' element={<Contact />} />
          <Route index path='/tokenomics' element={<Tokenomics />} />
          <Route index path='/roadmap' element={<Roadmap />} />
          <Route index path='/why' element={<Why />} />
          <Route index path='/mission-vision' element={<Mission />} />
          <Route index path='/faqs' element={<Faqs />} />
        </Routes>
      </Base>
    </BrowserRouter>
  );
}

export default App;
