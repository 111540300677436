/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import Timer from "../utils/Timer";

function Hero() {
  return (
    <div className='container-fluid hero hero-bg pt-5 pb-3'>
      <div className='container '>
        <div className='sideText'>推动以太坊链上衍生品生态系统的增长</div>
        <div className='row'>
          <div className='col-md-4'>
            <div className='hero-box'>
              <Timer />
              <video
                className='  mt-2 '
                height={250}
                autoPlay
                muted
                loop
                controls
                playsInline>
                <source src='https://imgur.com/ie1mGum.mp4' type='video/mp4' />
              </video>
              <p>
                First-ever zero-day futures trade with a 25-hour{" "}
                <span className='fs-6'>
                  time constraint on a Telegram bot. <br />{" "}
                  <span style={{ color: "#bfff47" }}>
                    {" "}
                    75% Revenue share from our futures bot trading.
                  </span>
                </span>
              </p>
            </div>
          </div>
          <div className='col-md-8 text-center'>
            <div className='hero-box bozz' style={{ marginTop: "-50px" }}>
              <div className='row'>
                <div className='col-sm-3 col-6'>
                  <h2>$6,930,002</h2>
                  <h5>24h Trade Volume</h5>
                </div>
                <div className='col-sm-3 col-6'>
                  <h2>$161,290,741</h2>
                  <h5>All Time Trade Volume</h5>
                </div>
                <div className='col-sm-3 col-6'>
                  <h2>100+</h2>
                  <h5>TG Bots</h5>
                </div>
                <div className='col-sm-3 col-6'>
                  <h2>700+</h2>
                  <h5>Community delegates</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row icnons position-fixed end-0 mx-5 bottom-0 my-2'>
          <div className='col-md-12 text-end'>
            <p className='next-para'>Experience</p>
            <div className=' text-end'>
              <Link to='/features' className='hero-arrow'>
                <BsArrowRight size={30} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
