/** @format */

import React from "react";

import { Link } from "react-router-dom";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

function Why() {
  return (
    <>
      <div
        className='container-fluid showroom-bg why pt-2'
        style={{ marginTop: "-70px" }}>
        <div className='container pt-5'>
          <div className='row'>
            <div className='col-md-4 list-items m-auto'>
              <ul className='list-unstyled '>
                <li>
                  <Link to='/why' className='active mx-3'>
                    Why $0xFutures
                  </Link>
                </li>
                <li>
                  <Link to='/roadmap'>Roadmap</Link>
                </li>
                <li>
                  <Link to='/tokenomics'>Tokenomics</Link>
                </li>
                <li>
                  <Link to='/mission-vision'>Mission & Vision</Link>
                </li>

                <li>
                  <Link to='/news'>0xFutures news</Link>
                </li>
              </ul>
            </div>
            <div className='col-md-8'>
              <div className='detail-row pt-5' style={{ height: "75vh" }}>
                <div className='row'>
                  <div className='col-md-6 m-auto modal-contents'>
                    <h1>Why Investing in $0xFutures</h1>
                    <p>
                      Why You Should Deliberate Investing in Zero Day Futures
                      Token
                    </p>
                    <p>
                      Investing in 0xFutures can be a game-changer in your
                      crypto portfolio. Cryptocurrency trading tokens have shown
                      the potential to deliver substantial profits in remarkably
                      short time frames. These tokens often experience
                      significant price surges driven by factors like viral
                      marketing, celebrity endorsements, social media trends,
                      and dynamic community-driven projects. Take UniBot, for
                      example, which recently witnessed an astonishing surge of
                      over 400%, reaching an all-time peak at $219, and now
                      trades at $73.
                    </p>
                  </div>
                  <div className='col-md-6 modal-img m-auto'>
                    <img src='assets/why0.png' alt='' className='w-100' />
                  </div>
                </div>
                <div className='m-auto modal-contents'>
                  <p>
                    This success story serves as a compelling reminder of the
                    immense growth potential within the crypto market.
                    Furthermore, one lucky member journey with 0xFutures who was
                    selected to test demo of 0xfutures bot resulted in a
                    remarkable 952.14% profit, demonstrating the incredible
                    gains that can be achieved with this innovative platform.
                    Don't miss out on the opportunity to be part of the next big
                    crypto success story with 0xFutures.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='row position-fixed icnons end-0 mx-5 bottom-0 my-2'>
            <div className='col-md-12 text-end'>
              <p className='next-para'>Why $0xFutures</p>
              <div className='next-btn text-end'>
                <Link to='/features' className='prev-arrow'>
                  <BsArrowRight size={30} />
                </Link>
                <Link to='/roadmap' className='next-arrow'>
                  <BsArrowLeft size={30} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Why;
